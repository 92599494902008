import { ENV } from '../environments/secret-env';

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.qa.genalyte.com',
  appUrl: 'https://operator.qa.genalyte.com',
  scanner: {
    resourcesPath: '../../../../../../../../assets/dwt-resources',
    dwtProductKey: ENV.SCANNER_NONPROD_KEY,
    uploadTargetURL: '/apps/operator/documents',
  },
  natsServer: 'ws://127.0.0.1:4223/',
  useNAT: false,
};
